<template>
  <div class="upload_html">
    <div class="upload_cover" @click="clickOpenUpload">
      <slot v-if="showCoverImg == ''"></slot>
      <img v-else :src="showCoverImg" />
    </div>
    <input
      ref="coverRef"
      type="file"
      name="imgfile"
      accept="image/png,image/jpeg,image/jpg,image/webp"
      @change="changeUploadCover"
    />
  </div>
  <!-- 裁剪弹框 -->
  <el-dialog
    v-model="clipShow"
    width="800px"
    top="10vh"
    title="裁剪封面"
    @closed="dialogClosed"
  >
    <div class="div_set_coper">
      <div class="div_coper_body">
        <vue-picture-cropper
          v-if="copperShow"
          :boxStyle="{
            width: '100%',
            height: '100%',
            backgroundColor: '#f8f8f8',
            margin: 'auto',
          }"
          :img="croperImg"
          :options="cropperOption"
        />
      </div>
      <div class="cpper_list" v-if="sizeList.length > 0">
        <ul>
          <li
            class="cp_us"
            :class="index == nowIndex ? 'li_color' : ''"
            v-for="(val, index) in sizeList"
            :key="index"
            @click="clickChangeRatio(val, index)"
          >
            {{ val.title }}
          </li>
        </ul>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="clipShow = false">取消</el-button>
        <el-button
          type="primary"
          @click="clickConfirmClip"
          :loading="confirmBtn.loading"
          >{{ confirmBtn.content }}</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits, defineExpose } from "vue";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import { dataURLtoFile } from "@/utils/index";
import { ElMessage } from "element-plus";
import {
  fileUpload, //上传阿里云
} from "@/api/api.js";

/**
 * modelValue:v-model双向绑定(非必要)--key
 * showImg: 默认组件是否展示已上传图片(非必要)--string
 * cropSize:裁剪框的尺寸比例,也可传递多种尺寸切换裁剪--number/list[]
 * customShow:是否自定义控制上传图片显示--Boole
 * imgOption:输出图片的限定配置：例maxWidth，maxHeight
 */
// ["modelValue", "showImg", "cropSize", "customShow"]
const props = defineProps({
  modelValue: {
    type: String,
  },
  showImg: {
    type: String,
    default: "",
  },
  cropSize: {
    required: true,
    default: 4 / 3,
  },
  customShow: {
    type: Boolean,
    default: false,
  },
  imgOption: {
    type: Object,
    default: function () {
      return {};
    },
  },
});
/**
 * @change(imgObj) - 上传完成后触发
 */
const emit = defineEmits(["update:modelValue", "change"]);
// ---cropper---配置
const cropperOption = {
  viewMode: 1,
  dragMode: "crop",
  imageSmoothingQuality: "high",
  outputType: "png",
};

//---裁剪---
let clipShow = ref(false);
let showCoverImg = ref(""); //视频封面
let croperImg = ref("");
let fileName = ref("");
let confirmBtn = ref({
  content: "确认",
  loading: false,
});
let copperShow = ref(true);
// 上传ref
let coverRef = ref(null);
// 尺寸切换
let sizeList = ref([]);
let nowIndex = ref(0);

watch(
  () => props.showImg,
  (nv) => {
    // 继承前封面
    showCoverImg.value = nv;
  }
);

stertOption();

function stertOption() {
  showCoverImg.value = props.showImg;
  if (typeof props.cropSize == "number") {
    cropperOption.aspectRatio = props.cropSize;
  } else {
    sizeList.value = props.cropSize.map((val) => {
      return {
        title: val,
        size: val.split(":")[0] / val.split(":")[1],
      };
    });
    cropperOption.aspectRatio = sizeList.value[0].size;
  }
}
// 上传封面
function changeUploadCover(e) {
  let imgArr = Array.from(e.target.files);
  let imgfile = imgArr[0];
  if (!imgfile) return;
  if (
    ["image/png", "image/jpeg", "image/jpg", "image/webp"].indexOf(
      imgfile.type
    ) == -1 ||
    imgfile.size > 5242880
  ) {
    ElMessage({
      showClose: true,
      icon: "WarningFilled",
      message: "当前图片大小/格式不符合规定！",
    });
    return;
  }
  fileName.value = imgfile.name;
  let imgsrc = URL.createObjectURL(imgfile);
  croperImg.value = imgsrc;
  clipShow.value = true;
}
// 确认裁剪
async function clickConfirmClip() {
  let img64 = await cropper.getDataURL(props.imgOption);
  let imgSet = new Image();
  imgSet.src = img64;
  imgSet.onload = async () => {
    const file = dataURLtoFile(img64, fileName.value);
    confirmBtn.value = {
      content: "裁剪中...",
      loading: true,
    };
    let imgObj = await uploadImg(file);
    if (!props.customShow) {
      showCoverImg.value = imgObj.data.fileUrl;
    }
    emit("update:modelValue", imgObj.data.fileUrl);
    emit("change", {
      url: imgObj.data.fileUrl,
      width: imgSet.width,
      height: imgSet.height,
    });
    clipShow.value = false;
  };
}
// 上传图片
function uploadImg(file) {
  let formData = new FormData();
  formData.append("file", file);
  return new Promise((resolve) => {
    fileUpload(formData).then((res) => {
      resolve(res);
    });
  });
}
// 改变裁剪
function clickChangeRatio(val, index) {
  copperShow.value = false;
  nowIndex.value = index;
  cropperOption.aspectRatio = val.size;
  setTimeout(() => {
    copperShow.value = true;
  }, 100);
}
// 弹框关闭
function dialogClosed() {
  coverRef.value.value = "";
  confirmBtn.value = {
    content: "确认",
    loading: false,
  };
}

// 开启上传流程
function clickOpenUpload() {
  coverRef.value.click();
}

defineExpose({ clickOpenUpload });
</script>
  
<style lang="scss" scoped>
.upload_html {
  width: 100%;
  height: 100%;
  .upload_cover {
    width: 100%;
    height: 100%;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  > input {
    display: none;
  }
}
.el-button {
  width: 80px;
}
.el-button--primary {
  &:hover {
    background-color: $subject-color-t-hover;
  }
}
.div_set_coper {
  .div_coper_body {
    width: 100%;
    height: 400px;
    padding: 8px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .cpper_list {
    > p {
      color: #afb6bf;
      font-size: 13px;
      margin: 0px 0px 18px;
      padding-left: 8px;
    }
    ul {
      display: flex;
      padding-left: 8px;

      li {
        white-space: nowrap;
        cursor: pointer;
        padding: 10px 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        margin-right: 12px;
        border: 1px solid #797878;
      }
      .li_color {
        color: #7b3dde;
        background: #eee3fe;
        border: 1px solid transparent;
      }
    }
  }
}
</style>