<template>
  <div class="div_set_message">
    <SetPassWorld v-if="cardType == 0" @next="clickSkip"></SetPassWorld>
    <SetUserInfo v-else></SetUserInfo>
    <!-- <span class="hover" @click="clickSkip">跳过</span> -->
  </div>
</template>

<script setup>
import { ref } from "vue";
import SetPassWorld from "@/components/card/setPassWorld.vue";
import SetUserInfo from "@/components/card/setUserInfo.vue";
// import { getLocalStor } from "@/utils/index";
import { useRouter } from "vue-router";
const router = useRouter();

let userObj = JSON.parse(localStorage.getItem("userLogin"));
let cardType = ref(0); //0设置密码 1设置个人信息

if (!userObj || userObj.isreg == 0) {
  router.replace({ name: "login" });
}

// 跳过
function clickSkip() {
  switch (cardType.value) {
    case 0:
      cardType.value = 1;
      break;

    default:
      router.push({
        name: "informationCollection",
      });
      break;
  }
}
</script>

<style lang='scss' scoped>
.hover {
  position: absolute;
  top: 60px;
  right: 170px;
  font-size: 16px;
  font-weight: 500;
  color: #425669;
}
</style>