<template>
  <div class="div_card">
    <h3 class="h3_title">个人信息设置</h3>
    <div class="div_input_body">
      <p class="p_explain">头像</p>
      <div class="div_upload_img">
        <UploadCover :cropSize="1" v-model="userObj.avatar">
          <img class="img_none" :src="userObj.avatar" alt="" />
        </UploadCover>
      </div>
      <p class="p_explain p_img">点击图片上传头像</p>
      <InputText ref="nickname" v-model="userObj.nickname"></InputText>
    </div>
    <button
      type="button"
      class="butsubmit"
      :class="canSubmit() ? 'btnGroupOne' : 'btnGroupOne'"
      @click="clickSubmit"
    >
      确定
    </button>
  </div>
</template>

<script setup>
import { ref } from "vue";
import UploadCover from "@/components/uplode/uploadCover.vue";
import InputText from "@/components/input/inputText.vue";
import { ElMessage } from "element-plus";
// import { getLocalStor } from "@/utils/index";
// import { setUserInfo } from "@/api/login";
import { uploadHeadimg, modifyNickname, findByMobile } from "@/api/api.js";
import router from "@/router";
let wxUser = JSON.parse(localStorage.getItem("wxLogin"));
let userObj = ref({
  avatar: "",
  nickname: "",
});

// --ref--
let nickname = ref(null);

// 验证
function verification() {
  let isOk = true;
  let worrIng = "";
  // if (userObj.value.avatar == "") {
  //   isOk = false;
  //   worrIng = "请上传头像";
  // } else if (userObj.value.nickname == "") {
  //   isOk = false;
  //   nickname.value.worring = true;
  //   worrIng = "请输入用户名";
  // }

  if (!isOk)
    ElMessage({
      message: worrIng,
      type: "error",
    });
  return isOk;
}
function canSubmit() {
  let isok = true;
  for (const key in userObj.value) {
    if (userObj.value[key] == "") {
      isok = false;
      break;
    }
  }
  return isok;
}
const mor = ref([]);
// 设置默认昵称
async function getNickname() {
  let user = JSON.parse(localStorage.getItem("userLogin"));
  findByMobile(user.phone).then((res) => {
    mor.value = res.data;
    if (res.data) {
      userObj.value.avatar = res.data?.headimgurl;
      userObj.value.nickname = res.data?.nickname;
    } else {
      userObj.value.avatar = wxUser.headimgurl;
      userObj.value.nickname = wxUser.nickname;
    }

    userObj.value.avatar =
      userObj.value.avatar ||
      "https://keymemo.oss-cn-shanghai.aliyuncs.com/2023/08/01/7900f40d7c4c4100a2704ed64a544d64默认头像.png";
    userObj.value.nickname =
      userObj.value.nickname ||
      "Key_" + user.phone.slice(user.phone.length - 4, user.phone.length);
  });
}
getNickname();
// 提交
async function clickSubmit() {
  if (!verification()) return;
  let user = JSON.parse(localStorage.getItem("userLogin"));
  if (userObj.value.avatar == "") {
    userObj.value.avatar = mor.value.headimgurl;
    let res1 = await uploadHeadimg(userObj.value.avatar, user.phone);
    let res2 = await modifyNickname(user.phone, userObj.value.nickname);
    if (res1.code == 200 && res2.code == 200) {
      router.push({
        name: "informationCollection",
      });
    }
  } else {
    let res1 = await uploadHeadimg(userObj.value.avatar, user.phone);
    let res2 = await modifyNickname(user.phone, userObj.value.nickname);
    if (res1.code == 200 && res2.code == 200) {
      router.push({
        name: "informationCollection",
      });
    }
  }
}
</script>

<style lang='scss' scoped>
.div_card {
  padding: 40px;
  text-align: center;
  .p_explain {
    margin: 30px 0px 0px;
  }
  .div_input_body {
    text-align: left;
    .div_upload_img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0px auto;
      .img_none {
        width: 80px;
        height: 80px;
      }
    }
    .p_img {
      margin: 12px 0px 28px;
      text-align: center;
    }
    .flex_between {
      margin-top: 16px;
      line-height: 1;
      font-size: 14px;
      color: #1f2328;
    }
  }
  .butsubmit {
    width: 100%;
    height: 60px;
    margin: 24px 0px 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
