<template>
  <div class="div_card">
    <h3 class="h3_title">设置登录密码</h3>
    <p class="p_explain">密码需要8-20位，至少含字母、数字、字符的任意两种</p>
    <div class="div_input_body">
      <InputPassworld
        v-model="newObj.passworld"
        ref="passworld"
      ></InputPassworld>
      <InputPassworld
        placeholder="确认你的密码"
        v-model="newObj.confirm_password"
        ref="confirm_password"
      ></InputPassworld>
    </div>
    <button
      type="button"
      class="butsubmit"
      :class="canSubmit() ? 'btnGroupOne' : 'btnGroupNone'"
      @click="clickSubmit"
    >
      确定
    </button>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import InputPassworld from "@/components/input/inputPassworld.vue";
import { ElMessage } from "element-plus";
// import { getLocalStor } from "@/utils/index";
// import { setUserPassword } from "@/api/login";
import { setPassword } from "@/api/api.js";
import md5 from "js-md5";

const emit = defineEmits(["next"]);

let newObj = ref({
  passworld: "", //密码
  confirm_password: "", //密码
});
let userObj = JSON.parse(localStorage.getItem("userLogin"));
// ---ref---
let passworld = ref(null);
let confirm_password = ref(null);

// 验证
function verification() {
  let isOk = true;
  let worrIng = "";
  let reg =
    /^(?!^\d+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^a-z0-9]+$)(?!^[^A-Z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S*$/;
  // 验证码登录
  if (newObj.value.passworld == "") {
    isOk = false;
    passworld.value.worring = true;
    worrIng = "请输入密码";
  } else if (reg.test(newObj.value.passworld) == false) {
    isOk = false;
    passworld.value.worring = true;
    worrIng = "密码不符合要求";
  } else if (newObj.value.passworld != newObj.value.confirm_password) {
    isOk = false;
    passworld.value.worring = true;
    confirm_password.value.worring = true;
    worrIng = "两次密码输入不一致";
  } else if (newObj.value.passworld.length < 8) {
    isOk = false;
    passworld.value.worring = true;
    worrIng = "密码长度不符合规定";
  }

  if (!isOk)
    ElMessage({
      message: worrIng,
      type: "error",
    });
  return isOk;
}
function canSubmit() {
  let isok = true;
  for (const key in newObj.value) {
    if (newObj.value[key] == "" && key != "sms_key") {
      isok = false;
      break;
    }
  }
  return isok;
}
// 提交
async function clickSubmit() {
  if (!verification()) return;
  // setUserPassword({
  //   user_id: userObj.id,
  //   token: userObj.token,
  //   ...newObj.value,
  // }).then(() => {
  //   emit("next");
  // });
  setPassword(userObj.phone, md5(newObj.value.passworld)).then(() => {
    emit("next");
  });
}
</script>

<style lang='scss' scoped>
.div_card {
  padding: 40px;
  text-align: center;
  .p_explain {
    margin: 8px 0px 32px;
  }
  .div_input_body {
    text-align: left;
    > div {
      margin-bottom: 24px;
    }
    .flex_between {
      margin-top: 16px;
      line-height: 1;
      font-size: 14px;
      color: #1f2328;
    }
  }
  .butsubmit {
    width: 100%;
    height: 60px;
    margin: 24px 0px 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>